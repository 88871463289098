import React from 'react';
import { Icon, Spinner } from '@chakra-ui/core';

import { rem } from '@/utils/theme';
import Button from '../Button';

type Props = {
  isAudioOn: boolean;
  isAudioLoading?: boolean;
} & React.ComponentProps<typeof Button>;

const AudioButton: React.FC<Props> = ({
  isAudioOn,
  isAudioLoading = false,
  ...rest
}) => {
  return (
    <Button
      as="button"
      display="flex"
      alignItems="center"
      data-testid="audio-btn"
      variant={isAudioOn ? 'green' : 'whiteGreenOutline'}
      borderWidth="2px"
      borderStyle="solid"
      borderColor="brand.deepSeaGreen"
      paddingY={2}
      paddingX={[2, 3]}
      position="absolute"
      zIndex={100}
      width="auto"
      transition="background-color, cubic-bezier(1, 0.43, 1, 1), 300ms"
      {...rest}
    >
      Audio {isAudioOn ? 'on' : 'off'}
      {isAudioLoading && isAudioOn ? (
        <Spinner
          width={rem('20px')}
          height={rem('20px')}
          color="white"
          marginLeft={[rem('20px'), 3]}
          speed="0.80s"
        />
      ) : (
        <Icon
          name={isAudioOn ? 'audioOn' : 'audioOff'}
          width={rem('20px')}
          height={rem('20px')}
          marginLeft={[rem('20px'), 3]}
        />
      )}
    </Button>
  );
};
export default AudioButton;
