/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import {
  AspectRatioBox,
  Flex,
  Heading,
  Image,
  Text,
  useTheme,
} from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Footer, Header } from '@/components/layout';
import { rem } from '@/utils/theme';
import { AKIRA_SVG_ASPECT_RATIO } from '@/utils/constants';

type Props = FlexProps;

// Breakpoints used to collapse content for shorter screens (e.g. laptops, landscape tablets)
const horizontalBreakpoint = rem('1024px');
const verticalBreakpoint = rem('900px');

const HomeDesktop: React.FC<Props> = ({ ...rest }) => {
  const theme = useTheme();
  return (
    <Flex direction="column" minHeight="full" width="full" {...rest}>
      <Flex
        direction="column"
        flexShrink={0}
        overflow="hidden"
        minHeight="100vh"
        css={css`
          min-height: -webkit-fill-available;
        `}
      >
        <Header marginBottom={-8} paddingBottom={0} />
        <Flex
          alignItems="center"
          flexGrow={1}
          flexShrink={0}
          justifyContent="center"
          paddingBottom={4}
          position="relative"
        >
          <Image
            alt=""
            src={require('@/images/shapes/homepage-desktop-background.svg')}
            left="50%"
            position="absolute"
            top="50%"
            transform="translateX(-48%) translateY(-48%)"
            width="auto"
            css={css`
              height: ${rem('600px')};

              @media (min-height: ${verticalBreakpoint}) and (min-width: ${horizontalBreakpoint}) {
                height: ${rem('800px')};
              }
            `}
            ignoreFallback
          />
          <Image
            alt=""
            src={require('@/images/shapes/homepage-desktop-side.svg')}
            left={0}
            opacity={0.7}
            position="absolute"
            width="auto"
            css={css`
              bottom: 0;
              height: ${rem('350px')};
              transform: translateX(-85%);

              @media (min-width: ${rem('1024px')}) {
                bottom: 5%;
                transform: translateX(-80%);
              }

              @media (min-width: ${rem('1350px')}) {
                bottom: 20%;
                height: ${rem('400px')};
                transform: translateX(-60%);
              }
            `}
            ignoreFallback
          />
          <Image
            alt=""
            src={require('@/images/shapes/homepage-desktop-side.svg')}
            maxHeight={rem('360px')}
            opacity={0.7}
            position="absolute"
            right="0"
            width="auto"
            css={css`
              bottom: 0;
              height: ${rem('220px')};
              transform: translateX(85%);

              @media (min-width: ${rem('1024px')}) {
                bottom: 10%;
                transform: translateX(65%);
              }

              @media (min-width: ${rem('1350px')}) {
                bottom: 10%;
                height: ${rem('325px')};
                transform: translateX(50%);
              }
            `}
            ignoreFallback
          />
          <AspectRatioBox
            ratio={AKIRA_SVG_ASPECT_RATIO}
            position="relative"
            css={css`
              width: ${rem('95px')};

              @media (min-height: ${verticalBreakpoint}) and (min-width: ${horizontalBreakpoint}) {
                width: ${rem('125px')};
              }
            `}
          >
            <Image alt="" src={require('@/images/akira.svg')} ignoreFallback />
          </AspectRatioBox>
          <Flex
            direction="column"
            marginLeft={5}
            position="relative"
            css={css`
              width: ${rem('375px')};

              @media (min-height: ${verticalBreakpoint}) and (min-width: ${horizontalBreakpoint}) {
                width: ${rem('440px')};
              }
            `}
          >
            <Heading
              as="h1"
              color="brand.text"
              fontSize="5xl"
              fontWeight="bold"
              css={css`
                font-size: ${theme.fontSizes['4xl']};

                @media (min-height: ${verticalBreakpoint}) and (min-width: ${horizontalBreakpoint}) {
                  font-size: ${theme.fontSizes['5xl']};
                }
              `}
            >
              Meet Akira
            </Heading>
            <Flex
              direction="column"
              css={css`
                font-size: ${rem('16px')};
                margin-bottom: ${theme.space['3']};
                margin-top: ${theme.space['3']};

                @media (min-height: ${verticalBreakpoint}) and (min-width: ${horizontalBreakpoint}) {
                  font-size: ${theme.fontSizes.xl};
                  margin-bottom: ${theme.space['5']};
                  margin-top: ${theme.space['5']};
                }
              `}
            >
              <Text color="brand.text">
                Akira is a care worker just like you.
                <br />
                Soon you will be helping her bring greater meaning to the people
                she cares for.
                <br />
                To learn more, visit{' '}
                <a href="https://meaningfulageing.org.au/meet-akira/">
                  Meaningful Ageing Australia
                </a>
                .
              </Text>
              <Text
                color="brand.text"
                css={css`
                  margin-bottom: ${theme.space['3']};
                  margin-top: ${theme.space['3']};

                  @media (min-height: ${verticalBreakpoint}) and (min-width: ${horizontalBreakpoint}) {
                    margin-bottom: ${theme.space['5']};
                    margin-top: ${theme.space['5']};
                  }
                `}
              >
                This is an interactive experience for care workers to learn more
                about spiritual care.
              </Text>
              <Text color="brand.text">
                If you want to know more contact us{' '}
                <a href="mailto:admin@meaningfulage.org.au">
                  admin@meaningfulage.org.au
                </a>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </Flex>
  );
};

export default HomeDesktop;
