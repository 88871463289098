/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { Box, Flex, Icon, Image, Text, useTheme } from '@chakra-ui/core';
import type { BoxProps, FlexProps } from '@chakra-ui/core';

import { rem } from '@/utils/theme';
import { fadeIn } from '@/utils/animations';
import type { Scene as SceneType } from '@/utils/types';
import type { CustomTheme } from '@/utils/theme';
import HeartButton from '../HeartButton';

type Props = {
  scene: SceneType;
  imageData?: string;
  heartEnabled?: boolean;
  forceHeartPressAnimation?: boolean;
  dialogueProps?: BoxProps;
  onNext?: () => void;
} & FlexProps;

const dialoguePaddingEm = 0.2;

const Scene: React.FC<Props> = ({
  scene,
  imageData,
  heartEnabled = true,
  forceHeartPressAnimation = false,
  dialogueProps,
  onNext,
  ...rest
}) => {
  const theme = useTheme() as CustomTheme;
  const isAkiraTalking = scene.dialogue?.name === 'Akira';
  const dialogueBgColor = isAkiraTalking ? 'white' : theme.colors.brand.text;
  const dialogueTextColor = isAkiraTalking ? theme.colors.brand.text : 'white';

  // Show heart after 1 second
  const [showHeart, setShowHeart] = useState(false);
  useEffect(() => {
    if (!heartEnabled || scene.hotspot === undefined) {
      setShowHeart(false);
      return undefined;
    }

    const timer = setTimeout(() => setShowHeart(true), 1000);
    return () => clearTimeout(timer);
  }, [scene]);

  return (
    <Flex alignItems="center" position="relative" width="full" {...rest}>
      <Box flexShrink={0} position="relative" width="full">
        <Image
          aria-hidden="true"
          alt={scene.alt}
          src={imageData ?? scene.src}
          width="100%"
          ignoreFallback
        />
        {showHeart && scene.hotspot && (
          <HeartButton
            label={scene.hotspot.label}
            forcePressAnimation={forceHeartPressAnimation}
            onClick={() => {
              onNext?.();
            }}
            position="absolute"
            left={scene.hotspot.x}
            transform="translateX(-50%) translateY(-50%)"
            top={scene.hotspot.y}
            zIndex={1}
          />
        )}
      </Box>
      {scene.dialogue && (
        <Box
          bottom={0}
          fontSize={['xl', '3xl']}
          left="50%"
          maxWidth={rem('500px')}
          padding={4}
          position="absolute"
          transform="translateX(-50%)"
          width="100%"
          {...dialogueProps}
        >
          <Text
            aria-hidden="true"
            backgroundColor={dialogueBgColor}
            borderColor={dialogueBgColor}
            borderLeftWidth={`${dialoguePaddingEm * 2}em`}
            boxShadow={`${dialoguePaddingEm * 2}em 0 0 ${dialogueBgColor}`}
            color={dialogueTextColor}
            display="inline"
            lineHeight={1.55}
            paddingY={`${dialoguePaddingEm}em`}
            css={css`
              box-decoration-break: clone;
            `}
          >
            {scene.dialogue.name && <strong>{scene.dialogue.name}: </strong>}
            {scene.dialogue.text}
          </Text>
        </Box>
      )}
      {showHeart && scene.hotspot && (
        <Flex
          alignItems="center"
          animation={`${fadeIn} 1s`}
          bottom={0}
          direction="column"
          fontSize={['sm', 'lg']}
          fontWeight="bold"
          left={0}
          maxWidth={rem('500px')}
          padding={4}
          position="absolute"
          width="100%"
        >
          <Text
            aria-hidden="true"
            backgroundColor="brand.parisDaisy"
            borderRadius={['20px', '30px']}
            maxWidth="100%"
            padding={[2, 4]}
            textAlign="center"
            width="auto"
          >
            {/* Replace ♥ in text with an SVG icon */}
            {scene.hotspot.label.includes('♥') ? (
              <>
                {scene.hotspot.label.split('♥')[0]}
                <Icon
                  name="heart"
                  color="brand.deepSeaGreen"
                  height="1em"
                  marginX="0.2em"
                  width="1em"
                />
                {scene.hotspot.label.split('♥')[1]}
              </>
            ) : (
              scene.hotspot.label
            )}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default Scene;
