import React from 'react';
import { AspectRatioBox, Flex, Image } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import {
  AKIRA_SVG_ASPECT_RATIO,
  GIORGIO_SVG_ASPECT_RATIO,
} from '@/utils/constants';

type Props = FlexProps;

const AkiraAndGiorgio: React.FC<Props> = ({ children, ...rest }) => (
  <Flex alignItems="flex-end" flexShrink={0} height="full" {...rest}>
    <AspectRatioBox ratio={AKIRA_SVG_ASPECT_RATIO} flexShrink={0} width="44%">
      <Image alt="" src={require('@/images/akira.svg')} ignoreFallback />
    </AspectRatioBox>
    <AspectRatioBox ratio={GIORGIO_SVG_ASPECT_RATIO} flexShrink={0} width="56%">
      <Image alt="" src={require('@/images/giorgio.svg')} ignoreFallback />
    </AspectRatioBox>
  </Flex>
);

export default AkiraAndGiorgio;
