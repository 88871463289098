/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  Stack,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Footer, Header } from '@/components/layout';
import { AkiraAndGiorgio, Button, IconButton } from '@/components/ui';
import { track } from '@/utils/gtag';
import { rem } from '@/utils/theme';
import type { CustomTheme } from '@/utils/theme';

type Props = {
  drawer: ReturnType<typeof useDisclosure>;
} & FlexProps;

// Breakpoints used to collapse content for shorter screens (e.g. laptops, landscape tablets)
const horizontalBreakpoint = rem('1024px');
const verticalBreakpoint = rem('900px');

const LearnMore: React.FC<Props> = ({ drawer, ...rest }) => {
  const theme = useTheme() as CustomTheme;

  const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    track.externalLink(
      `${e.currentTarget.text.replace(/ /g, '_')}_${e.currentTarget.href}`,
      1
    );
  };

  return (
    <Flex direction="column" width="full" {...rest}>
      <Flex
        direction="column"
        flexShrink={0}
        overflow="hidden"
        height="full"
        width="full"
      >
        <Header paddingBottom={[0, 0]} />
        <Flex
          alignItems="center"
          direction="column"
          flexGrow={1}
          flexShrink={0}
          justifyContent="center"
          paddingX={[3, 0]}
          paddingBottom={3}
          paddingTop={0}
          position="relative"
        >
          <Flex
            alignItems="center"
            flexShrink={0}
            justifyContent="center"
            margin="auto 0"
            position="relative"
            width="full"
            css={css`
              /* Remove margin: auto on IE11 as it breaks layout */
              @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                margin: 0;
              }
            `}
          >
            <Image
              alt=""
              src={require('@/images/shapes/learn-more-background.svg')}
              left="50%"
              maxWidth="none"
              opacity={0.3}
              position="absolute"
              top="50%"
              width="auto"
              css={css`
                height: 167%;
                transform: translateX(-55%) translateY(-30%);

                @media (min-width: ${(theme.breakpoints as string[])[0]}) {
                  height: ${rem('620px')};
                  transform: translateX(-50%) translateY(-53%);
                }

                @media (min-height: ${verticalBreakpoint}) and (min-width: ${horizontalBreakpoint}) {
                  height: ${rem('800px')};
                }
              `}
              ignoreFallback
            />
            <AkiraAndGiorgio
              display={['none', 'flex']}
              marginRight={5}
              position="relative"
              css={css`
                width: ${rem('235px')};

                @media (min-height: ${verticalBreakpoint}) and (min-width: ${horizontalBreakpoint}) {
                  width: ${rem('280px')};
                }
              `}
            />
            <Flex
              direction="column"
              height="full"
              justifyContent="center"
              position="relative"
              textAlign="center"
              css={css`
                max-width: none;

                @media (min-width: ${(theme.breakpoints as string[])[0]}) {
                  max-width: ${rem('285px')};
                }

                @media (min-height: ${verticalBreakpoint}) and (min-width: ${horizontalBreakpoint}) {
                  max-width: ${rem('380px')};
                }
              `}
            >
              <Flex alignItems="flex-end">
                <Heading
                  color="brand.text"
                  fontFamily="body"
                  fontSize={['lg', rem('21px')]}
                  fontWeight="700"
                  marginRight={2}
                  textAlign="left"
                  css={css`
                    @media (min-height: ${rem('550px')}) {
                      padding-bottom: ${theme.space['4']};
                    }

                    @media (min-width: ${(theme.breakpoints as string[])[0]}) {
                      padding-bottom: 0;
                    }
                  `}
                >
                  Find out more about spiritual care
                </Heading>
                <AkiraAndGiorgio
                  display={['flex', 'none']}
                  height="auto"
                  justifyContent="center"
                  width="33%"
                  css={css`
                    margin-bottom: ${rem('-64px')};

                    @media (min-height: ${rem('550px')}) {
                      margin-bottom: 0;
                    }
                  `}
                />
              </Flex>
              <Flex
                direction="column"
                maxWidth={rem('330px')}
                width={['auto']}
                css={css`
                  margin-top: ${theme.space['3']};

                  @media (min-height: ${rem('550px')}) {
                    margin-top: ${theme.space['1']};
                  }

                  @media (min-width: ${(theme.breakpoints as string[])[0]}) {
                    margin-top: ${theme.space['4']};
                  }

                  @media (min-height: ${verticalBreakpoint}) and (min-width: ${horizontalBreakpoint}) {
                    margin-top: ${theme.space['5']};
                  }
                `}
              >
                <Button
                  as="a"
                  zIndex={1}
                  {...{
                    href: 'https://www.youtube.com/watch?v=H_Ood7RZ41Y',
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    onClick: onLinkClick,
                  }}
                >
                  Watch a video
                </Button>
                <Button
                  as="a"
                  css={css`
                    margin-bottom: ${theme.space['3']};
                    margin-top: ${theme.space['3']};

                    @media (min-width: ${rem('350px')}) {
                      margin-bottom: ${theme.space['4']};
                      margin-top: ${theme.space['4']};
                    }

                    @media (min-height: ${verticalBreakpoint}) and (min-width: ${horizontalBreakpoint}) {
                      margin-bottom: ${theme.space['5']};
                      margin-top: ${theme.space['5']};
                    }
                  `}
                  {...{
                    href:
                      'https://rise.articulate.com/share/ZddSH4WaVoc66QAAKkea3DxuWL6lj6vS#/',
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    onClick: onLinkClick,
                  }}
                >
                  Do an e-module
                </Button>
                <Button
                  as="a"
                  variant="yellow"
                  {...{
                    href: 'http://eepurl.com/g-X9XH',
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    onClick: onLinkClick,
                  }}
                >
                  Get your free learning guide
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            display={['flex', 'none']}
            flexWrap="wrap"
            justifyContent="center"
            marginTop={4}
          >
            <Flex position="relative" alignItems="center">
              <Link
                href="https://www.surveymonkey.com/r/NQ699DF"
                target="_blank"
                rel="noopener noreferrer"
                color="brand.text"
                textAlign="center"
                paddingY={1}
                paddingX={3}
                width="auto"
                fontSize={['sm', 'lg', 'lg']}
                fontWeight={600}
                css={css`
                  &:focus {
                    outline: auto;
                    text-decoration: underline;
                  }
                `}
              >
                Give your feedback
              </Link>
            </Flex>
            <Button
              variant="textLink"
              alignSelf="center"
              paddingY={1}
              width="auto"
              fontSize={['sm', 'lg', 'lg']}
              onClick={drawer.onOpen}
              css={css`
                &:focus,
                &:hover {
                  text-decoration: underline;
                }
                &:focus {
                  outline: auto;
                }
              `}
            >
              Acknowledgements
            </Button>
          </Flex>
        </Flex>
        <Flex
          justifyContent="flex-end"
          display={['none', 'flex']}
          marginTop={4}
        >
          <Flex position="relative" alignItems="center">
            <Link
              href="https://www.surveymonkey.com/r/NQ699DF"
              target="_blank"
              rel="noopener noreferrer"
              color="brand.text"
              textAlign="center"
              paddingY={1}
              paddingX={3}
              lineHeight={1.3}
              width="auto"
              fontSize="lg"
              fontWeight={600}
              css={css`
                &:focus {
                  outline: auto;
                  text-decoration: underline;
                }
              `}
            >
              Give your feedback
            </Link>
          </Flex>
          <Button
            variant="textLink"
            alignSelf="center"
            paddingY={1}
            width="auto"
            onClick={drawer.onOpen}
            css={css`
              &:focus,
              &:hover {
                text-decoration: underline;
              }
              &:focus {
                outline: auto;
              }
            `}
          >
            Acknowledgements
          </Button>
        </Flex>

        <Footer display={['none', 'flex']} />
      </Flex>
      <Drawer isOpen={drawer.isOpen} onClose={drawer.onClose}>
        <DrawerOverlay />
        <DrawerContent
          maxWidth={rem('665px')}
          overflowY="auto"
          paddingX={[5, 12]}
          paddingY={6}
          width="full"
        >
          <IconButton
            icon="cross"
            alignSelf="flex-end"
            marginBottom={[5, 10]}
            width="auto"
            onClick={drawer.onClose}
          >
            Close
          </IconButton>
          <Heading
            color="brand.text"
            fontFamily="body"
            flexShrink={0}
            fontSize="2xl"
            fontWeight="bold"
          >
            Acknowledgements
          </Heading>
          <Stack
            color="brand.text"
            flexShrink={0}
            fontSize="sm"
            marginTop={6}
            spacing="1em"
          >
            <Text>
              Meet Akira phase one was developed with input from many people.
            </Text>
            <Text>Thank you to everyone involved, especially:</Text>
            <List
              paddingLeft={3}
              stylePos="outside"
              styleType="disc"
              spacing="1em"
            >
              <ListItem>The older people in our co-design group.</ListItem>
              <ListItem>
                The PCAs in our co-design and user testing groups.
              </ListItem>
              <ListItem>
                The project reference group:
                <List marginTop="1em">
                  <ListItem>Brightwater (to Dec 2019)</ListItem>
                  <ListItem>Baptistcare WA</ListItem>
                  <ListItem>Fresh Hope</ListItem>
                  <ListItem>Hall and Prior</ListItem>
                  <ListItem>UnitingCare Qld</ListItem>
                </List>
              </ListItem>
              <ListItem>
                With academic input from:
                <List marginTop="1em">
                  <ListItem>
                    Professor John Swinton, University of Aberdeen.
                  </ListItem>
                  <ListItem>Dr Richard Egan, University of Otago.</ListItem>
                </List>
              </ListItem>
              <ListItem>
                And PCA recruitment assistance from Mercy Health and VMCH.
              </ListItem>
            </List>
            <Text>
              The project was made possible thanks to support from Prime Super.
            </Text>
            <Text>Designed and built in Australia by Portable.</Text>
          </Stack>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default LearnMore;
