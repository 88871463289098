/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx, keyframes } from '@emotion/core';
import { Box, Flex, Icon } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { fadeIn } from '@/utils/animations';

const pulse = keyframes`
  0% {
    height: 84px;
    width: 84px;
  }
  50% {
    height: 102px;
    width: 102px;
  }
  100% {
    height: 84px;
    width: 84px;
  }
`;

const pressed = keyframes`
  0% {
    height: 84px;
    width: 84px;
  }
  100% {
    height: 252px;
    width: 252px;
  }
`;

type Props = {
  label: string;
  forcePressAnimation?: boolean;
  onClick: () => void;
} & FlexProps;

const HeartButton: React.FC<Props> = ({
  label,
  forcePressAnimation = false,
  onClick,
  ...rest
}) => {
  const [pressing, setPressing] = useState(false);

  const handleClick = () => {
    // Play click animation before calling onClick prop
    setPressing(true);
    setTimeout(() => {
      onClick();
    }, 200);
  };

  const showPressAnimation = pressing || forcePressAnimation;

  return (
    <Flex
      as="button"
      aria-label={label}
      animation={`${fadeIn} 1s`}
      className={showPressAnimation ? 'pressed' : ''}
      outline="none"
      onClick={handleClick}
      css={css`
        /* Add stroke to heart icon when button is focused */
        &:focus .heart-icon circle {
          stroke: #0d4a64;
          stroke-width: 3px;
        }
        /* Hide stroke while "press" animation is playing */
        &.pressed:focus .heart-icon circle {
          stroke-width: 0;
        }
      `}
      {...rest}
    >
      {/* Heart Icon */}
      <Icon
        name="heartCircle"
        className={`heart-icon ${showPressAnimation ? 'pressed' : ''}`}
        borderRadius="50%"
        height="102px"
        position="relative"
        width="102px"
        zIndex={1}
        css={css`
          padding: 18px;
          transition: padding 0.5s;

          &:hover {
            padding: 14px;
          }

          &.pressed {
            height: 252px;
            transition: height 1.5s, padding 1.5s, width 1.5s;
            width: 252px;
          }
        `}
      />
      {/* Animated Border */}
      <Box
        animation={
          showPressAnimation
            ? `${pressed} 1.5s forwards`
            : `${pulse} 3s infinite`
        }
        backgroundColor="brand.ripeLemon"
        borderRadius="50%"
        height="84px"
        left="50%"
        opacity={0.5}
        position="absolute"
        top="50%"
        transform="translateX(-50%) translateY(-50%)"
        width="84px"
      />
    </Flex>
  );
};

export default HeartButton;
