/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { PseudoBox } from '@chakra-ui/core';
import type { PseudoBoxProps } from '@chakra-ui/core';

import { assertNever } from '@/utils/types';

type Props = {
  variant?:
    | 'green'
    | 'yellow'
    | 'grey'
    | 'whiteHover'
    | 'white'
    | 'textLink'
    | 'transparent'
    | 'whiteOutline'
    | 'whiteGreenOutline';
  disabled?: boolean;
} & (
  | {
      as?: 'button';
      onClick: () => void;
    }
  | {
      as: 'div';
    }
  | {
      as: 'a';
      href?: string;
      target?: string;
      rel?: string;
    }
) &
  Omit<PseudoBoxProps, 'as'>;

const Button = React.forwardRef<HTMLElement, Props>(
  ({ as = 'button', variant = 'green', onClick, children, ...rest }, ref) => {
    let backgroundColor;
    let hoverBackgroundColor;
    let hoverBorderColor;
    let textColor;
    switch (variant) {
      case 'green':
        backgroundColor = 'brand.deepSeaGreen';
        hoverBackgroundColor = 'brand.text';
        hoverBorderColor = 'brand.text';
        textColor = 'white';
        break;
      case 'yellow':
        backgroundColor = 'brand.parisDaisy';
        hoverBackgroundColor = 'brand.ripeLemon';
        textColor = 'brand.text';
        break;
      case 'grey':
        backgroundColor = 'brand.mysticGrey';
        hoverBackgroundColor = 'brand.heatherGrey';
        textColor = 'brand.text';
        break;
      case 'whiteOutline':
        backgroundColor = 'white';
        hoverBackgroundColor = 'brand.whiteRockGrey';
        textColor = 'brand.deepSeaGreen';
        break;
      case 'whiteHover':
        backgroundColor = 'white';
        hoverBackgroundColor = 'brand.whiteRockGrey';
        hoverBorderColor = 'brand.whiteRockGrey';
        textColor = 'brand.deepSeaGreen';
        break;
      case 'whiteGreenOutline':
        backgroundColor = 'white';
        hoverBackgroundColor = 'rgba(13, 74, 100, 0.16)';
        textColor = 'brand.deepSeaGreen';
        break;
      case 'white':
        backgroundColor = 'white';
        hoverBackgroundColor = 'white';
        textColor = 'brand.deepSeaGreen';
        break;
      case 'transparent':
        backgroundColor = 'transparent';
        hoverBackgroundColor = 'transparent';
        textColor = 'brand.deepSeaGreen';
        break;
      case 'textLink':
        backgroundColor = 'transparent';
        hoverBackgroundColor = 'transparent';
        textColor = 'brand.text';
        break;
      default:
        assertNever(variant);
    }

    return (
      <PseudoBox
        as={as}
        ref={ref}
        backgroundColor={backgroundColor}
        borderRadius="sm"
        color={textColor}
        display="flex"
        fontSize="lg"
        fontWeight={600}
        justifyContent="center"
        lineHeight={1.3}
        outline="none"
        padding={3}
        position="relative"
        textDecoration="none"
        width="full"
        _hover={{
          backgroundColor: hoverBackgroundColor,
          borderColor: hoverBorderColor,
        }}
        css={css`
          &:focus {
            text-decoration: underline;
          }
        `}
        onClick={onClick}
        {...rest}
      >
        {children}
      </PseudoBox>
    );
  }
);

export default Button;
