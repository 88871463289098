/* eslint-disable react/no-this-in-sfc */
/** @jsx jsx */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { css, jsx } from '@emotion/core';
import NextLink from 'next/link';
import { Box, Flex } from '@chakra-ui/core';
import svgToDataURI from 'mini-svg-data-uri';
import type { FlexProps } from '@chakra-ui/core';

import AudioContext from '@/utils/audioContext';
import { AudioButton, BackButton, Button, ReplayButton } from '@/components/ui';
import useCSSAnimation from '@/utils/useCSSAnimation';
import { rem } from '@/utils/theme';
import type { Scene as SceneType } from '@/utils/types';
import { BackgroundShape, Scene } from './components';

const defaultFadeDuration = 400;
const defaultAutoplayWait = 1000;

const generateScreenReaderText = (scene: SceneType) => {
  let text = scene.alt;
  if (scene.dialogue) {
    if (scene.dialogue.name) {
      text = `${text} ${scene.dialogue.name} says:`;
    }
    text = `${text} ${scene.dialogue.text}`;
  }
  if (scene.hotspot) {
    text = `${text} ${scene.hotspot.label}`;
  }
  return text;
};

type Props = {
  scenes: Readonly<SceneType[]>;
  onSceneChange?: (index: number) => void;
} & FlexProps;

const Story: React.FC<Props> = ({ scenes, onSceneChange, ...rest }) => {
  const [index, setIndex] = useState<number | null>(null);
  const preloadedImages = useRef<{ [id: number]: string | undefined }>({});
  const scene = index !== null ? scenes[index] : null;
  const { isAudioOn, setIsAudioOn } = useContext(AudioContext);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioFiles, setAudioFiles] = useState<{
    mp3: string | undefined;
    wav: string | undefined;
  }>({ mp3: undefined, wav: undefined });
  const [isReplayVisible, setIsReplayVisible] = useState(false);
  const [isAudioLoading, setIsAudioLoading] = useState(false);

  // Fade in first scene on mount (also necessary so `aria-live` element is empty on first render)
  useEffect(() => {
    setIndex(0);
  }, []);

  // Control fade in/out animation for scene transitions
  const currSceneOpacity = useCSSAnimation('opacity', 0, 1000);
  const nextSceneOpacity = useCSSAnimation('opacity', 0, 1000);
  // Control fade in/out animation of scene dialogue (used to delay dialogue after "press heart" scenes)
  const currSceneDialogueOpacity = useCSSAnimation('opacity', 1, 1000);
  const nextSceneDialogueOpacity = useCSSAnimation('opacity', 1, 1000);
  // Control the colour of the background shape
  const backgroundOpacity = useCSSAnimation('opacity', 0, 1000);
  // Force the "heart button" press animation to still play if the user presses "Next"
  const [forceHeartPressAnimation, setForceHeartPressAnimation] = useState(
    false
  );
  // Control the "disabled" state of the "Next" button
  // NOTE: The visual and clickable states controlled separately so that users with
  // screen readers can still proceed on "heart button" scenes by pressing "Next"
  const [nextDisabled, setNextDisabled] = useState(false);
  const [nextClickDisabled, setNextClickDisabled] = useState(false);

  const onBack = () => {
    if (index === null || index === 0) {
      return;
    }
    // Skip any "autoplay" scenes when going back
    for (let i = index - 1; i >= 0; i -= 1) {
      if (scenes[i].animation?.type !== 'autoplay') {
        setIndex(i);
        onSceneChange?.(i);
        if (scenes[i].hotspot === undefined) {
          setNextDisabled(false);
          setNextClickDisabled(false);
        } else {
          setNextDisabled(true);
          setNextClickDisabled(true);
        }
        // Set the background colour to what it should be
        backgroundOpacity.update(scenes[i].positiveMoment ?? 0, 0);
        return;
      }
    }
  };

  const onNext = () => {
    if (index === null || scene === null) {
      return;
    }
    setIsAudioLoading(true);
    const cb = () => {
      setIndex(index + 1);
      onSceneChange?.(index + 1);
    };
    const nextScene = scenes[index + 1];
    // Disable "Next" button if the next scene is autoplay or has a "heart button"
    if (
      nextScene.animation?.type === 'autoplay' ||
      nextScene.hotspot !== undefined
    ) {
      setNextDisabled(true);
      setNextClickDisabled(true);
    } else {
      setNextDisabled(false);
      setNextClickDisabled(false);
    }
    switch (scene.animation?.type) {
      case 'fadeOut': {
        // Fade out over 0.8s then wait 1s before continuing
        const duration = 0.8 * 1000;
        const wait = 1 * 1000;
        currSceneOpacity.update(0, duration);
        // Animate the background shape colour over the same time
        backgroundOpacity.update(nextScene.positiveMoment ?? 0, duration);
        setTimeout(() => {
          cb();
          if (nextScene.animation?.type !== 'fadeIn') {
            currSceneOpacity.update(1, 0);
          }
        }, duration + wait);
        break;
      }
      default: {
        // Animate a short fade
        const fadeDuration =
          scene.animation?.fadeDuration ?? defaultFadeDuration;
        currSceneOpacity.update(0, fadeDuration);
        nextSceneOpacity.update(1, fadeDuration);
        // Animate the background shape colour over the same time
        backgroundOpacity.update(nextScene.positiveMoment ?? 0, fadeDuration);
        // Continue after fade animation is finished
        setTimeout(() => {
          cb();
          currSceneOpacity.update(1, 0);
          nextSceneOpacity.update(0, 0);
        }, fadeDuration);

        // If the current scene is a "press heart" scene, delay the fading in of the next dialogue
        if (scene.hotspot !== undefined) {
          currSceneDialogueOpacity.update(0, 0);
          nextSceneDialogueOpacity.update(0, 0);
          setTimeout(() => {
            currSceneDialogueOpacity.update(1, fadeDuration);
            nextSceneDialogueOpacity.update(1, 0);
          }, fadeDuration + 50);
        } else {
          currSceneDialogueOpacity.update(1, 0);
          nextSceneDialogueOpacity.update(1, 0);
        }
        break;
      }
    }
  };

  const onReplay = () => {
    audioRef.current?.play();
    setIsReplayVisible(false);
  };

  // If the scene has a hotspot, set a timer to re-enable the "Next" button
  useEffect(() => {
    const timers: NodeJS.Timeout[] = [];
    if (scene?.hotspot !== undefined) {
      // Re-enable the "Next" button click after 2 seconds
      timers.push(
        setTimeout(() => {
          setNextClickDisabled(false);
        }, 2000)
      );
      // Show the "Next" button again after 10 seconds
      timers.push(
        setTimeout(() => {
          setNextDisabled(false);
        }, 10000)
      );
    }
    return () => timers.forEach((t) => clearTimeout(t));
  }, [scene]);

  // Preload the next 6 images and save them as data URIs
  useEffect(() => {
    if (index === null) {
      return;
    }
    for (let i = 1; i <= 6; i += 1) {
      const next = index + i;
      if (next < scenes.length && preloadedImages.current[next] === undefined) {
        const nextScene = scenes[next];
        preloadedImages.current[next] = '';
        const request = new XMLHttpRequest();
        request.open('GET', nextScene.src, true);
        request.onload = function onload() {
          if (this.status >= 200 && this.status < 400) {
            // https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
            preloadedImages.current[next] = svgToDataURI(this.response);
          }
        };
        request.send();
      }
    }
    // Clear out old images to save memory
    if (index !== null && index > 0) {
      preloadedImages.current[index - 1] = undefined;
    }
  }, [index]);

  // Control other scene transitions (fadeIn and autoplay)
  useEffect(() => {
    let timer: NodeJS.Timeout;
    switch (scene?.animation?.type) {
      case 'fadeIn':
        // Fade in over 0.8 seconds
        currSceneOpacity.update(1, 800);
        break;
      case 'autoplay': {
        // Move to next scene after 1 second
        const wait = scene.animation?.duration ?? defaultAutoplayWait;
        timer = setTimeout(() => {
          onNext();
        }, wait);
        break;
      }
      default:
        break;
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [index]);

  // setup replay events
  useEffect(() => {
    const currentAudio = audioRef.current;
    if (!window || currentAudio === null) {
      return undefined;
    }
    const handleShowReplay = () => setIsReplayVisible(true);
    currentAudio?.addEventListener('ended', handleShowReplay);
    return () => {
      currentAudio?.removeEventListener('ended', handleShowReplay);
    };
  }, []);

  // setup audio loading events
  useEffect(() => {
    if (!window || audioRef.current === null) {
      return undefined;
    }
    const handleHideSpinner = () => setIsAudioLoading(false);
    audioRef.current?.addEventListener('playing', handleHideSpinner);
    return () => {
      audioRef.current?.removeEventListener('playing', handleHideSpinner);
    };
  }, []);

  // we need to catch any rejected play promises so there are no errors
  const playVideo = async (): Promise<void> => {
    try {
      return await audioRef.current?.play();
    } catch (err) {
      return undefined;
    }
  };

  // load and play current audio on scene change
  useEffect(() => {
    if (index === null) {
      return;
    }
    const currentAudio = audioRef.current;
    setAudioFiles({
      mp3: scenes[index].audioMp3,
      wav: scenes[index].audioWav,
    });
    // setTimeout required for audio ref to finish updating with new audio source files running the load and play functions
    setTimeout(() => {
      currentAudio?.load();
      playVideo();
    }, 0);
  }, [index]);

  const gotoPrevPage = index !== null && index === 0;
  const gotoNextPage = index !== null && index === scenes.length - 1;

  return (
    <Flex direction="column" width="full" {...rest}>
      {index !== null && scenes[index].audioMp3 && (
        <ReplayButton
          onClick={onReplay}
          opacity={isReplayVisible && isAudioOn ? 1 : 0}
          left={[4, 6]}
          top={[4, 6]}
        />
      )}
      {index !== null && scenes[index].audioMp3 && (
        <AudioButton
          onClick={() => setIsAudioOn(!isAudioOn)}
          isAudioOn={isAudioOn}
          isAudioLoading={isAudioLoading}
          right={[4, 6]}
          top={[4, 6]}
        />
      )}
      <Flex
        direction="column"
        flexGrow={1}
        flexShrink={0}
        overflow="hidden"
        position="relative"
        width="full"
      >
        {/* IE11 doesn't support `filter: grayscale` so instead we set up two images and fade between them */}
        <BackgroundShape gray />
        <BackgroundShape
          style={{
            opacity: backgroundOpacity.value,
            transition: backgroundOpacity.transition,
          }}
        />
        <Flex
          flexGrow={1}
          margin="auto"
          maxWidth={rem('500px')}
          minHeight={rem('360px')}
          position="relative"
          width="full"
          css={css`
            max-width: ${rem('360px')};

            @media (min-height: ${rem('600px')}) {
              max-width: ${rem('500px')};
            }
          `}
        >
          <Box aria-atomic="true" aria-live="polite" className="sr-only">
            {scene !== null ? generateScreenReaderText(scene) : ''}
          </Box>
          {index !== null && scenes[index + 1] && (
            <Flex
              height="100%"
              position="absolute"
              width="100%"
              style={{
                opacity: nextSceneOpacity.value,
                transition: nextSceneOpacity.transition,
              }}
            >
              <Scene
                scene={scenes[index + 1]}
                imageData={preloadedImages.current[index + 1]}
                heartEnabled={false}
                dialogueProps={{
                  style: {
                    opacity: nextSceneDialogueOpacity.value,
                    transition: nextSceneDialogueOpacity.transition,
                  },
                }}
              />
            </Flex>
          )}
          <Flex
            height="100%"
            position="absolute"
            width="100%"
            style={{
              opacity: currSceneOpacity.value,
              transition: currSceneOpacity.transition,
            }}
          >
            {scene !== null && (
              <Scene
                scene={scene}
                imageData={
                  index !== null ? preloadedImages.current[index] : undefined
                }
                forceHeartPressAnimation={forceHeartPressAnimation}
                dialogueProps={{
                  style: {
                    opacity: currSceneDialogueOpacity.value,
                    transition: currSceneDialogueOpacity.transition,
                  },
                }}
                onNext={onNext}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        backgroundColor="brand.text"
        justifyContent="center"
        position="relative"
      >
        <Flex
          justifyContent="space-between"
          maxWidth={rem('500px')}
          paddingX={[4, 6]}
          paddingY={[3, 6]}
          width="full"
        >
          {gotoPrevPage ? (
            <NextLink href="/" passHref>
              <BackButton
                as="a"
                data-testid="back-btn"
                backgroundColor="brand.text"
                paddingLeft={0}
                width="calc(50% - 8px)"
              />
            </NextLink>
          ) : (
            <BackButton
              data-testid="back-btn"
              backgroundColor="brand.text"
              paddingLeft={0}
              width="calc(50% - 8px)"
              onClick={onBack}
            />
          )}
          {gotoNextPage ? (
            <NextLink href="/learn-more" as="/learn-more/" passHref>
              <Button
                as="a"
                data-testid="next-btn"
                variant="whiteHover"
                opacity={nextDisabled ? 0 : 1}
                width="calc(50% - 8px)"
              >
                Next
              </Button>
            </NextLink>
          ) : (
            <Button
              data-testid="next-btn"
              aria-label="Next"
              aria-disabled={nextClickDisabled}
              variant="whiteHover"
              className={nextDisabled ? 'disabled' : ''}
              border="2px solid"
              borderColor="white"
              width="calc(50% - 8px)"
              onClick={() => {
                if (nextClickDisabled) {
                  return;
                }
                setIsReplayVisible(false);
                // Play heart animation before calling onNext
                if (scene !== null && scene.hotspot !== undefined) {
                  setForceHeartPressAnimation(true);
                  setTimeout(() => {
                    onNext();
                  }, 200);
                  setTimeout(() => {
                    setForceHeartPressAnimation(false);
                  }, scene.animation?.fadeDuration ?? defaultFadeDuration);
                  return;
                }
                onNext();
              }}
              css={css`
                transition: background-color 0.5s, color 0.5s;
                &.disabled {
                  background-color: transparent;
                  color: white;
                  cursor: default;
                  text-decoration: none;
                }
              `}
            >
              {nextDisabled ? '...' : 'Next'}
            </Button>
          )}
        </Flex>
      </Flex>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} key="voiceover" autoPlay muted={!isAudioOn}>
        {audioFiles.mp3 && isAudioOn && (
          <>
            <source key="mp3" src={audioFiles.mp3} type="audio/mpeg" />
            <source key="wav" src={audioFiles.wav} type="audio/wav" />
          </>
        )}
      </audio>
    </Flex>
  );
};

export default Story;
