import React from 'react';
import NextLink from 'next/link';
import { Flex, Heading, Image } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Footer, Header } from '@/components/layout';
import { Button } from '@/components/ui';
import { rem } from '@/utils/theme';

type Props = FlexProps;

const Error404: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Flex direction="column" width="full" {...rest}>
      <Flex
        direction="column"
        flexShrink={0}
        overflow="hidden"
        height="full"
        width="full"
      >
        <Header paddingBottom={0} />
        <Flex
          as="main"
          alignItems="center"
          direction="column"
          flexGrow={1}
          flexShrink={0}
          justifyContent="center"
          paddingBottom={4}
          position="relative"
        >
          <Image
            alt=""
            src={require('@/images/akira-confused.svg')}
            height={[rem('210px'), rem('240px')]}
            ignoreFallback
          />
          <Heading
            as="h1"
            color="brand.text"
            fontSize={['3xl', '5xl']}
            fontWeight="bold"
            marginY={2}
          >
            Page not found
          </Heading>
          <NextLink href="/" passHref>
            <Button
              data-testid="back-btn"
              as="a"
              width={[rem('240px'), rem('300px')]}
            >
              Back to home
            </Button>
          </NextLink>
        </Flex>
        <Footer display={['none', 'flex']} />
      </Flex>
    </Flex>
  );
};

export default Error404;
