import { useState } from 'react';

const useCSSAnimation = (
  property: string,
  defaultValue: string | number,
  defaultTransitionMs: number
) => {
  const [value, setValue] = useState(defaultValue);
  const [transition, setTransition] = useState(
    `${property} ${defaultTransitionMs}ms`
  );

  const update = (newValue: string | number, newTransitionMs: number) => {
    setValue(newValue);
    setTransition(`${property} ${newTransitionMs}ms`);
  };

  return {
    value,
    transition,
    update,
  };
};

export default useCSSAnimation;
