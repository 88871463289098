/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Image, useTheme } from '@chakra-ui/core';
import type { ImageProps } from '@chakra-ui/core';

import { rem } from '@/utils/theme';

type Props = {
  gray?: boolean;
} & ImageProps;

const BackgroundShape: React.FC<Props> = ({ gray = false, ...rest }) => {
  const theme = useTheme();
  return (
    <Image
      alt=""
      src={
        gray
          ? require('@/images/shapes/story-background-gray.svg')
          : require('@/images/shapes/story-background.svg')
      }
      left="50%"
      maxWidth="none"
      position="absolute"
      top="50%"
      width="auto"
      css={css`
        height: 110%;
        transform: translateX(-45%) translateY(-43%);

        @media (min-width: ${(theme.breakpoints as string[])[0]}) {
          height: ${rem('860px')};
          transform: translateX(-50%) translateY(-40%);
        }
      `}
      ignoreFallback
      {...rest}
    />
  );
};

export default BackgroundShape;
