/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Flex, Icon, Text } from '@chakra-ui/core';

import Button from '../Button';

type Props = React.ComponentProps<typeof Button>;

const BackButton = React.forwardRef<HTMLElement, Props>(({ ...rest }, ref) => {
  return (
    <Button
      ref={ref}
      aria-label="Back"
      alignItems="center"
      borderRadius={0}
      justifyContent="flex-start"
      css={css`
        &:focus,
        &:hover {
          text-decoration: none;

          *:before {
            background-color: currentColor;
            bottom: -8px;
            content: '';
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }
      `}
      {...rest}
    >
      <Flex height="1em" position="relative" width="1.55555555556em">
        <Icon name="arrowLeft" height="100%" width="100%" />
      </Flex>
      <Text
        display={['none', 'block']}
        lineHeight={1}
        paddingLeft={3}
        position="relative"
      >
        Back
      </Text>
    </Button>
  );
});

export default BackButton;
