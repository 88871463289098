import React from 'react';
import { Box } from '@chakra-ui/core';

import { HomeDesktopHolding, HomeMobileHolding } from './components';

const Home: React.FC = () => {
  return (
    <Box as="main" height="full" width="full">
      <Box display={['none', 'flex']} height="full" width="full">
        <HomeDesktopHolding data-testid="home-desktop" />
      </Box>
      <Box display={['flex', 'none']} height="full" width="full">
        <HomeMobileHolding data-testid="home-mobile" />
      </Box>
    </Box>
  );
};

export default Home;
