/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Flex, Icon, Text } from '@chakra-ui/core';

import Button from '../Button';

type Props = {
  icon: string;
} & React.ComponentProps<typeof Button>;

const IconButton: React.FC<Props> = ({ icon, children, ...rest }) => {
  return (
    <Button
      variant="white"
      alignItems="center"
      borderRadius={0}
      flexShrink={0}
      css={css`
        &:hover,
        &:focus {
          text-decoration: none;

          *:before {
            background-color: currentColor;
            bottom: -8px;
            content: '';
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }
      `}
      {...rest}
    >
      <Text lineHeight={1} paddingRight={3} position="relative">
        {children}
      </Text>
      <Flex height="1em" position="relative">
        <Icon name={icon} height="100%" width="auto" />
      </Flex>
    </Button>
  );
};

export default IconButton;
