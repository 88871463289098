/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import {
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Header } from '@/components/layout';
import { rem } from '@/utils/theme';

type Props = FlexProps;

const HomeMobile: React.FC<Props> = ({ ...rest }) => {
  const theme = useTheme();
  return (
    <Flex direction="column" minHeight="full" width="full" {...rest}>
      <Flex
        backgroundColor="brand.text"
        direction="column"
        flexShrink={0}
        minHeight="100vh"
        css={css`
          min-height: -webkit-fill-available;
        `}
      >
        <Header
          backgroundColor="brand.desertStormGrey"
          marginBottom={-3}
          paddingBottom={0}
        />
        <Flex
          alignItems="center"
          direction="column"
          flexGrow={1}
          flexShrink={1}
          justifyContent="space-between"
          marginX="auto"
          minWidth={rem('320px')}
          position="relative"
          textAlign="center"
          width="full"
        >
          <Box
            flexGrow={1}
            minHeight={rem('150px')}
            maxHeight={rem('400px')}
            overflow="hidden"
            position="relative"
            width="full"
          >
            <Icon
              name="mobileHomepageShape"
              color="brand.desertStormGrey"
              height="100%"
              left="50%"
              minWidth={rem('400px')}
              position="absolute"
              transform="translateX(-50%)"
              width="100%"
              {...{
                preserveAspectRatio: 'none',
              }}
            />
            <Image
              alt=""
              src={require('@/images/akira.svg')}
              height="full"
              left="50%"
              position="absolute"
              transform="translateX(-50%)"
              width="auto"
              ignoreFallback
            />
          </Box>
          <Stack
            color="white"
            flexShrink={0}
            margin="auto"
            maxWidth={rem('350px')}
            paddingX={2}
            paddingY={4}
            spacing={1}
            width="full"
            css={css`
              font-size: ${theme.fontSizes.xs};

              @media (min-width: ${rem('350px')}) {
                font-size: ${rem('14px')};
              }
            `}
          >
            <Heading
              as="h1"
              fontWeight="bold"
              css={css`
                font-size: ${rem('23px')};

                @media (min-width: ${rem('350px')}) {
                  font-size: ${theme.fontSizes['3xl']};
                }
              `}
            >
              Meet Akira
            </Heading>
            <Text>
              Akira is a care worker just like you.
              <br />
              Soon you will be helping her bring greater meaning to the people
              she cares for.
              <br />
              To learn more, visit{' '}
              <a href="https://meaningfulageing.org.au/meet-akira/">
                Meaningful Ageing Australia
              </a>
              .
            </Text>
            <Text>
              This is an interactive experience for care workers to learn more
              about spiritual care.
            </Text>
            <Text>
              If you want to know more contact us{' '}
              <a href="mailto:admin@meaningfulage.org.au">
                admin@meaningfulage.org.au
              </a>
            </Text>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HomeMobile;
