import React from 'react';
import { Icon, Text } from '@chakra-ui/core';

import { rem } from '@/utils/theme';
import Button from '../Button';

type Props = React.ComponentProps<typeof Button>;

const ReplayButton: React.FC<Props> = ({ ...rest }) => {
  return (
    <Button
      aria-label="Replay"
      alignItems="center"
      borderRadius={0}
      justifyContent="flex-start"
      as="button"
      display="flex"
      variant="transparent"
      padding={0}
      position="absolute"
      zIndex={100}
      width="auto"
      marginTop={rem('5px')}
      _hover={{ textDecoration: 'underline', color: 'brand.text' }}
      transition="opacity 0.3s cubic-bezier(0, 0.69, 0.56, 1.14)"
      {...rest}
    >
      <Icon
        name="replay"
        width={rem('40px')}
        height={rem('40px')}
        marginRight={1}
      />
      <Text>Replay</Text>
    </Button>
  );
};

export default ReplayButton;
