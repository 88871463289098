export type Dictionary<T> = { [key: string]: T | undefined };

export const assertNever = (x: never): never => {
  throw new Error(`Unexpected object: ${x}`);
};

export type Scene = {
  src: string;
  alt: string;
  audioMp3?: string;
  audioWav?: string;
  hotspot?: {
    label: string;
    x: string;
    y: string;
  };
  dialogue?: {
    name?: string;
    text: string;
  };
  animation?: {
    type?: 'fadeIn' | 'fadeOut' | 'autoplay';
    duration?: number;
    fadeDuration?: number;
  };
  positiveMoment?: number;
};
