import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { rem } from '@/utils/theme';

type Props = {
  ariaHidden?: boolean;
} & FlexProps;

const Header: React.FC<Props> = ({ ariaHidden = false, ...rest }) => {
  return (
    <Flex
      as="header"
      aria-hidden={ariaHidden ? 'true' : 'false'}
      flexShrink={1}
      justifyContent="space-between"
      minWidth={rem('320px')}
      paddingX={[4, 5]}
      paddingY={[4, 5]}
      width="full"
      {...rest}
    >
      <Box
        as="a"
        height={[rem('29px'), rem('43px')]}
        tabIndex={ariaHidden ? -1 : 0}
        zIndex={1}
        {...{
          href: 'https://meaningfulageing.org.au/',
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
      >
        <Image
          alt="Meaningful Ageing Logo"
          src={require('@/images/meaningful-ageing-logo.svg')}
          ignoreFallback
          height="100%"
          maxWidth="none"
        />
      </Box>
    </Flex>
  );
};

export default Header;
