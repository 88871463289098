import React from 'react';
import {
  Modal as ChakraModal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/core';
import type { IModal, PseudoBoxProps } from '@chakra-ui/core';

import { fadeIn } from '@/utils/animations';
import { assertNever } from '@/utils/types';
import { rem } from '@/utils/theme';

type Props = {
  variant?: 'audio' | 'feedback' | 'tAndC';
  initialFocusRef?: React.RefObject<HTMLElement> | undefined;
} & Omit<IModal, 'children'> &
  PseudoBoxProps;

const Modal = React.forwardRef<HTMLElement, Props>(
  (
    {
      variant = 'tAndC',
      initialFocusRef,
      isOpen = false,
      onClose,
      children,
      ...rest
    },
    ref
  ) => {
    let contentBackgroundColor;
    let contentMarginX;
    let contentMarginY;
    let contentMaxWidth;
    let contentPadding;
    switch (variant) {
      case 'audio':
        contentBackgroundColor = 'brand.ripeLemon';
        contentMarginX = 2;
        contentMarginY = 0;
        contentMaxWidth = rem('448px');
        break;
      case 'feedback':
      case 'tAndC':
        contentBackgroundColor = 'white';
        contentMarginX = [2, 4];
        contentMarginY = [2, 4];
        contentMaxWidth = [rem('350px'), rem('400px')];
        contentPadding = [2, 3];
        break;
      default:
        assertNever(variant);
    }
    return (
      <ChakraModal
        ref={ref}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialFocusRef}
        closeOnOverlayClick={false}
        isCentered
        {...rest}
      >
        <ModalOverlay
          animation={`${fadeIn} 0.3s`}
          backgroundColor="rgba(146,143,133,0.7)"
        />
        <ModalContent
          animation={`${fadeIn} 0.5s`}
          boxShadow="none"
          color="brand.text"
          marginX={contentMarginX}
          marginY={contentMarginY}
          position="relative"
          overflow="hidden"
          backgroundColor={contentBackgroundColor}
          maxWidth={contentMaxWidth}
          padding={contentPadding}
        >
          {children}
        </ModalContent>
      </ChakraModal>
    );
  }
);

export default Modal;
