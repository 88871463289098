/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Flex, Heading, Text, useTheme } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { rem } from '@/utils/theme';

const verticalBreakpoint = rem('900px');

type Props = FlexProps;

const Footer: React.FC<Props> = ({ ...rest }) => {
  const theme = useTheme();
  return (
    <Flex
      as="footer"
      backgroundColor="brand.text"
      flexShrink={0}
      justifyContent="center"
      position="relative"
      width="full"
      css={css`
        padding: ${theme.space['5']};

        @media (min-height: ${verticalBreakpoint}) {
          padding: ${theme.space['8']};
        }
      `}
      {...rest}
    >
      <Flex direction="column" textAlign="center" width={rem('580px')}>
        <Heading
          color="white"
          fontSize="2xl"
          fontWeight="bold"
          css={css`
            margin-bottom: ${theme.space['1']};

            @media (min-height: ${verticalBreakpoint}) {
              margin-bottom: ${theme.space['4']};
            }
          `}
        >
          Who is Meaningful Ageing Australia?
        </Heading>
        <Text color="white" fontSize="lg" fontWeight="600">
          We are a not-for-profit membership-based organisation, focused on the
          social, emotional and spiritual wellbeing of older people.
        </Text>
      </Flex>
    </Flex>
  );
};

export default Footer;
